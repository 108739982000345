.banner-home{
    align-items: center;
    justify-content: space-around;
    padding-top: 2rem;
    display: flex;
    background: #EBEBEB;
    padding-bottom: 2rem;
}


.txt-banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.txt-banner p{
    text-align: center;
    max-width: 342px;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.txt-banner h1{
    text-align: center;
    font-weight: 400;
    line-height: 35px;
}

.txt-banner button{
    border: 1px solid black;
    padding: 10px 20px
}

.banner-home img{
    height: 430px;
}


/*Product*/

.title-product {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
  }
  
  .title-product hr {
    opacity: 1;
    height: 1px;
    width: 92px;
    border: none;
    background-color: black;
    margin: 0 10px;
  }

  .products-section{
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
  }

  .grid-product{
    margin-top: 5rem;
  }

  .grid-product .col {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.txt-grid{
    width: 300px;
}

.grid-product img{
    width: 500px;
}


/*Carousel*/

.products-carousel{
    margin-bottom: 5rem;
}

.carousel-prod .swiper{
    height: 400px;
}
.carousel-prod .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .carousel-prod .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #c3c3c3;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .carousel-prod .swiper-pagination-bullet-active {
    background-color: #333;
    transform: scale(1.3);
  }


  .carousel-prod .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;   
    flex-direction: column; 
    text-align: center;
    width: 200px !important;
  }


  .img-slider{
    display: flex;
    width: 100%;
    background: #F3EFEE;
    align-items: center;
    justify-content: center;
    height: 132px;
  }

  .img-slider img{
    max-width: 60px;
  }

  .txt-slider h4{
    background: #f9f9f9;
    font-size: 18px;
    
  }
  .txt-slider span{
    font-size: 14px;
    color: #6a6767;
  }

  /*Footer*/

  .footer{
    display: flex;
  
  
  }

  .l-footer, .r-footer{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    text-align: center
    ;
  }

  .l-footer{
    background: #BDAFA6;
  }

  .r-footer{
    background: #E2DBD5;
  }

  .r-footer h1{
    line-height: 30px;
  }
.r-footer p{
    margin-top: 1rem;
}

.l-footer img{
    height: 300px;
}












@media(max-width: 991px){
    .icones {
        display: flex;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer{
      flex-direction: column;     
      
      }

      
    .l-footer img {
         height: auto;
    }
    }
@media(max-width: 883px){
    .banner-home img {
        width: 400px;
        height: auto;
    }
}

@media(max-width: 767px){
    .banner-home img{
        display: none;
    }
}

@media(max-width: 505px){
    .grid-product .col img{
        width: 400px;
    }

    .grid-product .col{
        margin-bottom: 3rem;    
    }
}

@media(max-width: 425px){
    .grid-product .col img {
        width: 300px;
    }
    
    .priceCart{
        margin-right: 3rem;
    }

   
}