.contact{
    min-height: 70vh;
}

.banner-contact{
    background-image: url('../../assets/banner3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    position: relative;
    color: white;
    z-index: 1;
    margin-bottom: 2rem;
}

.banner-contact::before{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .banner-contact p{
    color: white;
    font-weight: 200;
    text-align: center;
    padding-top: 1rem;
  }

  .banner-contact h1{
    line-height: 1px;
    text-align: center;
    margin-bottom: 0;
  }

.form-contact{
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.form-flex{
    display: flex;
    
}

.form-contact form .form-control{
    border: none;
    background: #F6F4F0;
    margin-right: 10px;
    border-radius: 0;
}

.form-contact form button {
    border-color: black;

    background-color: transparent;
    border: 1px solid black;
    padding: 5px 10px;
    color: black;
    border-radius: 0;
}

.form-contact form button:hover{
    background: #ffd02e;
    color: black;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active  {
    background-color: #ffd02e !important;
    color: black !important;
}

.form-control:focus {
    color: black;
    background-color: none !important;
    border-color: transparent !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.contact h1{
    text-align: center;
    margin-top: 3rem;
}

.btn-form{
    display: flex;
    justify-content: center;
}