
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;900&display=swap');
 
 *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.containers{
    max-width: 1440px !important;
    margin: auto;
    width: 95%;
}

.container{
    max-width: 1440px !important;
    margin: auto;
    width: 95%;
}
 .containerMax{
    width: 100%;
} 

.body{
    background: #F5F5F5;
    
}

p{
    color: #323131;
}


.addToCartBttn {
    background-color: transparent;
    border: 1px solid black;
    padding: 0px 10px;
    color: black;
  }

  .addToCartBttn:hover {
    background: #ffd02e;
  }

.buttons-cart{
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.buttons-cart>div{
    gap: 1rem;
    display: flex;
}

.btnOrder{
    background: #DC4C4C;
    border: none;
    padding: 5px 35px;
    color: white;
    font-weight: 600;
    font-size: 16px;
}

a:hover{
    color: inherit !important;
}